import Vue from 'vue'
import App from "./app"
import router from './router'
import {BootstrapVue} from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import VueTextareaAutosize from 'vue-textarea-autosize'
import VueObserveVisibility from 'vue-observe-visibility'

import user from "./services/accounting/user";
import course from "./services/course/course";
import chat from "./services/course/chat";
import courseEnum from "./services/course/enums";
import sessions from "./services/course/session";
import i18n from "./services/ui/i18n/i18n";
import filters from "./services/ui/filters";
import colors from "./services/ui/colors";
import log from "./services/ui/log";
import installation from "./services/installation/installation";
import api from "./services/networking/apiclient";
import "../styles/styles.scss";

Vue.use(VueTextareaAutosize)
Vue.use(VueCookies)
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueObserveVisibility)

Vue.config.productionTip = false;

// APL
Vue.prototype.APL = {
    user,
    course,
    courseEnum,
    chat,
    sessions,
    colors,
    installation,
    api,
    i18n,
    log
};

// Filters
Vue.filter("trans", i18n.translateFilter);
Vue.filter("limit", filters.limitFilter);
Vue.filter("fileSize", filters.fileSizeFilter);
Vue.filter("attr", filters.attrFriendlyFilter);
Vue.filter("dateFormat", filters.dateFormatFilter);
Vue.filter("secToHms", filters.secToHmsFilter);
Vue.filter("nl2br", filters.nl2brFilter);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
