<template>

    <img v-if="value"
         class="app-media-img"
         :class="cssClass"
         :src="imageUrl"
         :alt="altText"
         :title="altText"/>

</template>

<script>
    export default {
        props: {
            value: Number,
            width: {
                type: Number,
                default: 0
            },
            height: {
                type: Number,
                default: 0
            },
            cssClass: String,
            altText: String
        },
        computed: {
            imageUrl() {
                return `${process.env.VUE_APP_API_BASEURL}/img/${this.value}/${this.width}x${this.height}`;
            }
        }
    }
</script>

