<template>
  <div id="app" :class="'app-lang-'+lang" :key="lang">
    <router-view/>
  </div>
</template>

<script>
export default {

  data() {
    return {
      lang: ""
    }
  },
  created() {
    this.lang = this.APL.i18n.store.current.key;
    
    // redraw everything on language change
    this.$root.$on('langChanged', () => {

      //console.log("Language changed");
      this.lang = this.APL.i18n.store.current.key;

    })
  }
}
</script>

