<template>
    <header class="mhead">
        <div class="container">
            <div class="mhead-wrapper">

                <router-link v-if="inst.info.entity.Theme_Logo_Media_Id" class="mhead-logo" to="/">
                    <image-cmp v-model="inst.info.entity.Theme_Logo_Media_Id" :height="200"></image-cmp>
                </router-link>

                <ul class="list-inline list-inline--sep mhead-tools">

                    <user-cmp></user-cmp>

                    <langs-cmp></langs-cmp>

                </ul>

            </div>
        </div>
    </header>
</template>

<script>
    import imageCmp from "../../../../commons/partials/media/image/image-visualizer.vue"
    import langsCmp from "./partials/langs";
    import userCmp from "./partials/user";

    export default {
        components: {
            langsCmp,
            imageCmp,
            userCmp
        },
        data() {
            return {
                inst: this.APL.installation.data
            }
        }
    }
</script>

