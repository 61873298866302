import Vue from 'vue'
import VueRouter from 'vue-router'

import Master from "./commons/master/main"
import userSvc from "./services/accounting/user";
import installationSvc from "./services/installation/installation";
import courseRoutes from "./views/in/course/routes"

Vue.use(VueRouter);

const routes = [
    // REDIRECTS
    {
        path: '/',
        redirect: '/app/in/dashboard'
    },
    {
        path: '/login',
        redirect: '/app/out/login'
    },
    {
        path: '/invite/:token',
        redirect: '/app/out/invite/:token'
    },
    {
        path: '/activate/:token',
        redirect: '/app/out/activate/:token'
    },
    {
        path: '/recovery/:token',
        redirect: '/app/out/recovery/:token'
    },
    {
        path: '/app/in',
        component: Master,
        children: [
            {
                path: 'dashboard',
                name: 'Home',
                component: () => import(/* webpackChunkName: "dashboard" */'./views/in/dashboard/index')
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "profile" */'./views/in/profile/profile')
            },
            ...courseRoutes
        ]
    },

    // PUBLIC PAGES
    {
        path: '/app/out',
        component: Master,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "public" */ './views/out/login/index')
            },
            {
                path: 'invite/:token',
                component: () => import(/* webpackChunkName: "public" */ './views/out/accounting/activation/index')
            },
            {
                path: 'recovery',
                name: 'Recover',
                component: () => import(/* webpackChunkName: "public" */ './views/out/accounting/recover/start')
            },
            {
                path: 'recovery/:token',
                component: () => import(/* webpackChunkName: "public" */ './views/out/accounting/recover/apply')
            },
            {
                path: 'register',
                name: 'Registration',
                component: () => import(/* webpackChunkName: "public" */ './views/out/accounting/registration/start')
            },
            {
                path: 'activate/:token',
                component: () => import(/* webpackChunkName: "public" */ './views/out/accounting/registration/activate')
            },
            {
                path: 'pages/:page',
                component: () => import(/* webpackChunkName: "public" */ './views/out/pages/index')
            },
        ]
    },

    // GATEWAYS
    {
        path: '/gw/autologin/:otp',
        component: () => import(/* webpackChunkName: "gateways" */ './views/gw/autologin')
    },
    {
        path: '/gw/autologin-course/:otp/:course',
        component: () => import(/* webpackChunkName: "gateways" */ './views/gw/autologin')
    }


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    return new Promise(resolve => {

        // make sure installation is inited
        installationSvc.init().then(() => {

            // check SSL
            if (installationSvc.data.info.entity.Sys_Use_Ssl && document.location.protocol !== 'https:') {
                let url = "https://" + document.location.hostname + document.location.pathname;
                document.location.href = url;
                return;
            }

            // if we're requesting a gateway stop testing
            if (to.fullPath.indexOf("/gw/") > -1) {
                next();
                resolve();
                return;
            }

            // if we're requesting a free text page stop testing
            if (to.fullPath.indexOf("/pages/") > -1) {
                next();
                resolve();
                return;
            }

            // define if we're requesting a private area link
            let private_area = to.fullPath.indexOf("/out") === -1;

            userSvc.isLoggedIn().then(resp => {

                // check if user is logged
                if (resp.logged) {

                    // ensure user data are loaded
                    userSvc.loadCurrent().then(() => {
                        if (private_area) {
                            next();
                        } else {
                            // avoid public link when logged
                            next({name: "Home"});
                        }
                    });

                } else {
                    if (private_area) {

                        let requested_url = window.location.pathname;

                        if (installationSvc.data.info.entity.Sys_Custom_Login_Url) {
                            document.location.href = installationSvc.data.info.entity.Sys_Custom_Login_Url + "?ru=" + requested_url;
                        } else {
                            next({name: "Login", query: {ru: requested_url}});
                        }

                    } else {
                        next();
                    }
                }
                resolve();
            });

        })


    });
});

export default router;
