import api from "../networking/apiclient";
import i18n from "@/services/ui/i18n/i18n";
import $ from "jquery";

export default {

    getMainData(id) {
        return new Promise(resolve => {

            api.post(`courses/main/get/${id}/main`).then(resp => {

                resolve(resp);

            });

        });
    },
    getTeacher(courseId, id) {
        return new Promise(resolve => {

            api.post(`courses/main/get/${courseId}/teacher/${id}`).then(resp => {

                resolve(resp);

            });

        });
    },
    getLessons(id) {
        return new Promise(resolve => {

            api.post(`courses/main/get/${id}/lessons`).then(resp => {

                let lessons = resp.lessons;
                applyStatus(lessons, resp.statuses);

                resolve(lessons);

            });

        });
    },
    getLibrary(id) {
        return new Promise(resolve => {

            let lang = i18n.store.current.key;

            api.post(`courses/tools/library/get/${id}/${lang}`).then(resp => {

                resolve(resp);

            });

        });
    },
    getFaqs(id) {
        return new Promise(resolve => {

            let lang = i18n.store.current.key;

            api.post(`courses/tools/faq/get/${id}/${lang}`).then(resp => {

                resolve(resp);

            });

        });
    },
    getWebinarsDone(courseId) {
        return new Promise(resolve => {

            api.post(`courses/webinars/list/done/${courseId}`).then(resp => {
                resolve(resp);
            });

        });
    },
    getWebinar(courseId, id) {
        return new Promise(resolve => {

            api.post(`courses/webinars/get/${courseId}/webinar/${id}`).then(resp => {
                resolve(resp);
            });

        });
    },
    getWebinarRecording(courseId, webinarId, recordingId) {
        return new Promise(resolve => {

            api.post(`courses/webinars/get-recording/${courseId}/webinar/${webinarId}/${recordingId}`).then(resp => {
                resolve(resp);
            });

        });
    },
    getWebinarForm(courseId, webinarId, formId) {
        return new Promise(resolve => {

            api.post(`courses/webinars/get-form/${courseId}/webinar/${webinarId}/${formId}`).then(resp => {
                resolve(resp);
            });

        });
    },
    getModuleAccessConfig(courseId, moduleId) {
        return new Promise(resolve => {

            api.post(`courses/module/access/pwd/config/${courseId}/${moduleId}`).then(resp => {
                resolve(resp);
            });

        });
    },
    trackWebinar(courseId, id) {
        return new Promise(resolve => {

            api.post(`courses/webinars/track/${courseId}/webinar/${id}`).then(resp => {
                resolve(resp);
            });

        });
    },
    toggleSidebar() {
        $("body").toggleClass("is-side-in");
    }

}

function applyStatus(lessons, statuses) {
    lessons.units.forEach(unit => {

        // apply unit status
        let status = statuses.find(i => {
            return i.Type === "Unit" && i.ItemId === unit.item.id;
        });

        if (status) {
            unit.status = {
                completed: status.Status === "Completed",
                locked: status.IsLocked,
                time: status.Time,
                firstTracking: status.FirstTracking,
                lastTracking: status.LastTracking,
                score: status.Score
            };
        } else {
            unit.status = {
                completed: false,
                locked: true
            };
        }

        // apply modules status
        unit.modules.forEach(module => {
            status = statuses.find(i => i.Type === "Module" && i.ItemId === module.item.id);
            if (status) {
                module.status = {
                    started: status.Status !== "NotStarted",
                    completed: status.Status === "Completed",
                    locked: status.IsLocked,
                    time: status.Time,
                    firstTracking: status.FirstTracking,
                    lastTracking: status.LastTracking,
                    score: status.Score
                };
            } else {
                module.status = {
                    started: false,
                    completed: false,
                    locked: true
                };
            }
        })

        // define unit progress
        let completed = unit.modules.filter(i => i.status.completed).length;
        if (completed === 0) {
            unit.status.progress = 0;
        } else {
            unit.status.progress = Math.floor(completed / unit.modules.length * 100);
        }

    })

}