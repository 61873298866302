export default {

    suggestForeColor(bgColor) {

        // https://codepen.io/davidhalford/pen/ywEva

        let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

        let hRed = hexToR(bgColor);
        let hGreen = hexToG(bgColor);
        let hBlue = hexToB(bgColor);


        function hexToR(h) {
            return parseInt((cutHex(h)).substring(0, 2), 16)
        }

        function hexToG(h) {
            return parseInt((cutHex(h)).substring(2, 4), 16)
        }

        function hexToB(h) {
            return parseInt((cutHex(h)).substring(4, 6), 16)
        }

        function cutHex(h) {
            return (h.charAt(0) == "#") ? h.substring(1, 7) : h
        }

        let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
        if (cBrightness > threshold) {
            return "#000000";
        } else {
            return "#ffffff";
        }
    },
    rgbColorToHex(color) {
        if (color.indexOf("rgba") > -1) {
            return rgbaToHex(color);
        } else {
            return rgbToHex(color);
        }
    }
}

function trim(str) {
    return str.replace(/^\s+|\s+$/gm, '');
}

function rgbToHex(rgb) {
    // Choose correct separator
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.substr(4).split(")")[0].split(sep);

    let r = (+rgb[0]).toString(16),
        g = (+rgb[1]).toString(16),
        b = (+rgb[2]).toString(16);

    if (r.length === 1)
        r = "0" + r;
    if (g.length === 1)
        g = "0" + g;
    if (b.length === 1)
        b = "0" + b;

    return "#" + r + g + b;
}

function rgbaToHex(rgba) {
    var inParts = rgba.substring(rgba.indexOf("(")).split(","),
        r = parseInt(trim(inParts[0].substring(1)), 10),
        g = parseInt(trim(inParts[1]), 10),
        b = parseInt(trim(inParts[2]), 10),
        a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
        if (part.length === 1) {
            outParts[i] = '0' + part;
        }
    })

    return ('#' + outParts.join(''));
}