import DateTime from "luxon/src/datetime";
import {Duration} from "luxon";
import i18n from "../ui/i18n/i18n.js"

export default {

    limitFilter(value, maxLength) {

        if (!value) return "";

        if (value.length > maxLength) {
            return value.substring(0, maxLength) + "...";
        } else {
            return value;
        }

    },
    fileSizeFilter(size) {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    },
    attrFriendlyFilter(val) {
        if (!val) return "";

        return val.replace(/"/g, '');
    },
    dateFormatFilter(isoDate, format) {
        if (!isoDate) return "";

        return DateTime
            .fromISO(isoDate)
            .setLocale(i18n.store.current.key)
            .toFormat(format);
    },
    secToHmsFilter(seconds) {

        // if (!seconds) return "";
        //
        // return new Date(seconds * 1000).toISOString().substr(11, 8);

        if (!seconds) return "";

        let result = Duration.fromObject({seconds: seconds});

        return result.toFormat("h:mm:ss");
    },
    nl2brFilter(input){
        if (typeof input === 'undefined' || input === null) {
            return '';
        }
        var breakTag = '<br>';
        return (input + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
}