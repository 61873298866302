<template>

    <div>
        <div v-html="style"></div>

    </div>

</template>

<script>
    import $ from "jquery";

    export default {
        computed: {
            style() {

                let colors = this.APL.installation.data.info.entity.Theme_Colors;

                if (!colors) return "";

                let result = `
                    <style>
                        :root {
                        --main-body-bg: ${colors.color_body_bg};
                        --main-head-bg: ${colors.color_header_bg};
                        --main-foot-bg: ${colors.color_footer_bg};
                        --main-primary: ${colors.color_theme};
                        --main-primary--rgb:${colors.color_theme.replace(/rgb\(([^)]+)\)/, "$1")};
                        }
                    </style>`;

                if (this.hasBgImage) {

                    let bg_image = `background-image: url('${process.env.VUE_APP_API_BASEURL}/img/${colors.bg_image.media_id}/0x0');`;
                    let bg_size = `background-size: ${colors.bg_image.layout === "cover" ? "cover" : "auto"};`;
                    let bg_repeat = `background-repeat: ${colors.bg_image.layout === "cover" ? "no-repeat" : "repeat"};`;

                    result = result + `
                    <style>
                        body {
                            ${bg_image}
                            ${bg_size}
                            ${bg_repeat}
                        }
                    </style>`;

                }

                return result;
            },
            hasBgImage() {
                let colors = this.APL.installation.data.info.entity.Theme_Colors;
                return colors.bg_image && colors.bg_image.enabled && colors.bg_image.media_id;
            }
        },
        created() {
            if (this.hasBgImage) {
                $("body").addClass("app-has-bg-image");
            }
        }
    }
</script>