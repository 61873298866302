let rootCmp = () => import(/* webpackChunkName: "courses-main" */ './index');
let homeCmp = () => import(/* webpackChunkName: "courses-main" */ './home/index');
let teacherCmp = () => import(/* webpackChunkName: "courses-main" */ './teacher/index');
let moduleCmp = () => import(/* webpackChunkName: "courses-modules" */ './module/index');
let webinarCmp = () => import(/* webpackChunkName: "courses-webinar" */ './webinar/index');
let webinarHomeCmp = () => import(/* webpackChunkName: "courses-webinar" */ './webinar/home/index');
let webinarSessionCmp = () => import(/* webpackChunkName: "courses-webinar" */ './webinar/session/index');
let webinarRecordingCmp = () => import(/* webpackChunkName: "courses-webinar" */ './webinar/recording/index');
let webinarFormCmp = () => import(/* webpackChunkName: "courses-webinar" */ './webinar/form/index');

export default [
    {
        name: "course",
        path: "course/:course",
        component: rootCmp,
        redirect: "course/:course/home",
        children: [
            {
                path: "home",
                component: homeCmp
            },
            {
                name: "course-module",
                path: "module/:module",
                component: moduleCmp
            },
            {
                name: "teacher-details",
                path: "teachers/:teacher",
                component: teacherCmp
            },
            {
                name: "webinar",
                path: "webinar/:webinar",
                component: webinarCmp,
                children: [
                    {
                        name: "webinar-home",
                        path: "home",
                        component: webinarHomeCmp
                    },
                    {
                        name: "webinar-session",
                        path: "session",
                        component: webinarSessionCmp
                    },
                    {
                        name: "webinar-form",
                        path: "form/:form",
                        component: webinarFormCmp
                    },
                    {
                        name: "webinar-recording",
                        path: "vod/:recording",
                        component: webinarRecordingCmp
                    }]
            }
        ]
    },


]