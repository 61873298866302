<template>
  <div>
    <div v-html="fontEmbed"></div>

    <div v-html="fontStyle"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fonts: {
        "Lato": {
          embed: "Lato",
          family: "Lato, sans-serif"
        },
        "Arvo": {
          embed: "Arvo",
          family: "'Arvo', serif"
        },
        "CrimsonText": {
          embed: "Crimson+Text",
          family: "'Crimson Text', serif"
        },
        "FiraSans": {
          embed: "Fira+Sans",
          family: "'Crimson Text', sans-serif"
        },
        "Merriweather": {
          embed: "Merriweather",
          family: "'Merriweather', serif"
        },
        "Nunito": {
          embed: "Nunito",
          family: "'Nunito', sans-serif"
        },
        "Vollkorn": {
          embed: "Vollkorn",
          family: "'Vollkorn', serif"
        },
        "WorkSans": {
          embed: "Work+Sans",
          family: "'Work Sans', sans-serif"
        },
        "RobotoCondensed": {
          embed: "Roboto+Condensed",
          family: "'Roboto Condensed', sans-serif"
        },
        "TitilliumWeb": {
          embed: "Titillium+Web",
          family: "'Titillium Web', sans-serif"
        }
      }
    }
  },
  computed: {
    font() {
      var theme_font = this.APL.installation.data.info.entity.Theme_Fonts;
      var result = this.fonts.Lato;

      if (theme_font && theme_font.main_font) {
        if (this.fonts[theme_font.main_font]) {
          result = this.fonts[theme_font.main_font];
        }
      }
      return result;
    },
    fontEmbed() {
      return `<link href="https://fonts.googleapis.com/css2?family=${this.font.embed}:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">`;
    },
    fontStyle() {
      return `

                <style media="screen">
                    :root {
                        --main-font-family: ${this.font.family};
                    }
                </style>
 `;
    }
  }
}
</script>

