<template>

  <li class="list-inline-item dropdown" v-if="user">
    <b-dropdown right
                toggle-class="btn--txt btn--icon-right"
                menu-class="dropdown-menu--bubble">

      <template v-slot:button-content>
        <span class="d-none d-md-block">{{ user.info.entity.First_Name }} {{ user.info.entity.Last_Name }}</span>
        <span class="icon icon-user"></span>
      </template>

      <b-dropdown-item class="mhead-fullname d-md-none">{{ user.info.entity.First_Name }}
        {{ user.info.entity.Last_Name }}
      </b-dropdown-item>

      <b-dropdown-item v-if="user.info.entity.Source==='Integrated'" class="list-lined-item">
        <router-link class="btn btn--txt btn--icon-left" :to="{name:'Profile'}">
          {{ terms.profile|trans }}
          <span class="icon icon-profile"></span>
        </router-link>
      </b-dropdown-item>

      <b-dropdown-item class="list-lined-item">
        <a class="btn btn--txt btn--icon-left" @click="logout">
          {{ terms.logout|trans }}
          <span class="icon icon-exit"></span>
        </a>
      </b-dropdown-item>

    </b-dropdown>
  </li>

</template>

<script>
import installationSvc from "@/services/installation/installation";

export default {
  data() {
    return {
      terms: {
        profile: {"it": "Il mio profilo", "en": "My profile"},
        logout: {"it": "Esci", "en": "Logout"},
      },
      user: this.APL.user.info
    }
  },
  created() {
    this.$root.$on('userStatusChanged', () => {
      this.reload();
    })

  },
  methods: {
    logout() {

      this.APL.user.logout().then(() => {

        // per maggior pulizia non usa il router e ricarica tutto
        if (installationSvc.data.info.entity.Sys_Custom_Login_Url) {
          document.location.href = installationSvc.data.info.entity.Sys_Custom_Login_Url;
        } else {
          document.location.href = "/app/out/login?logout";
        }


      })
    },
    reload() {

      this.APL.user.isLoggedIn().then(resp => {
        if (!resp.logged) {
          this.user = null;
        } else {
          this.APL.user.loadCurrent().then(() => {
            this.user = this.APL.user.info;
          })
        }
      })

    }
  }
}
</script>

