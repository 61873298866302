let null_module = {
    code: "",
    name: {it: "", en: ""}
};

export default {

    modules: [
        {
            code: "Text",
            name: {
                it: "Slides",
                en: "Slides",
            },
            icon:"icon-clipboard-text"
        },
        {
            code: "Video",
            name: {
                it: "Video",
                en: "Video"
            },
            icon:"icon-play"
        },
        {
            code: "Audio",
            name: {
                it: "Audio",
                en: "Audio"
            },
            icon:"icon-file-audio"
        },
        {
            code: "Scorm",
            name: {
                it: "Lezione scorm",
                en: "Scorm package"
            },
            icon:"icon-clapboard-play"
        },
        {
            code: "Quiz",
            name: {
                it: "Quiz interattivo",
                en: "Interactive Quiz"
            },
            icon:"icon-bubble-question"
        },
        {
            code: "Form",
            name: {
                it: "Modulo",
                en: "Form"
            },
            icon:"icon-clipboard-check"
        },

    ],
    getModule(code) {
        var item = this.modules.find(i => i.code === code);

        if (item) {
            return item;
        } else {
            return null_module;
        }
    }

}