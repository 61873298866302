import api from "../networking/apiclient";
// import cookies from "../utils/cookies";
// const COOKIE_NAME = "AplFrontend";

export default {
    info: null,
    isLoggedIn() {
        return new Promise(resolve => {

            api.post("accounting/access/check", null).then(resp => {
                resolve(resp);
            });

        });
    },
    login(email, password) {

        return new Promise(resolve => {

            let dto = {
                Email: email,
                Password: password
            };

            api.post("accounting/access/login", dto).then(resp => {

                // if (resp.Success) {
                //     cookies.setCookie(COOKIE_NAME, resp.Token);
                // }

                this.info = null; // force reload at next query

                resolve(resp);
            })
        })
    },
    autologin(otp) {

        return new Promise(resolve => {

            // cookies.eraseCookie(COOKIE_NAME);

            api.post(`accounting/access/autologin/${otp}`, null).then(resp => {

                //
                // if (resp.Success) {
                //     cookies.setCookie(COOKIE_NAME, resp.Token);
                // }

                this.info = null; // force reload at next query

                resolve(resp.Success);
            })
        })
    },
    logout() {
        return new Promise(resolve => {

            api.post("accounting/access/logout", null).then(() => {
                // cookies.eraseCookie(COOKIE_NAME);
                this.info = null;
                resolve();
            })
        })
    },

    loadCurrent() {
        return new Promise(resolve => {

            if (this.info) {
                resolve(this.info);
            } else {
                api.post("accounting/student/current", null).then((resp) => {
                    this.info = resp;
                    resolve(resp);
                })
            }
        })
    }
}