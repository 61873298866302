import cookies from "../../utils/cookies";
import api from "@/services/networking/apiclient";

const LANGS = {
        "it": {
            key: "it",
            menuName: "It",
            menuTooltip: "Fai clic per passare all'Italiano"
        },
        "en": {
            key: "en",
            menuName: "En",
            menuTooltip: "Click to switch to English"
        }
    },
    LANGS_STATES = {
        current: LANGS["it"],
        alternate: LANGS["en"]
    },
    COOKIE_NAME = "PreferredLang";

export default {

    store: {
        current: LANGS_STATES.current,
        alternate: LANGS_STATES.alternate
    },
    setCurrent(key) {

        // ad uso del filtro
        LANGS_STATES.current = LANGS[key];
        LANGS_STATES.alternate = (key === "it") ? LANGS["en"] : LANGS["it"];

        // ad uso delle views
        this.store.current = LANGS[key];
        this.store.alternate = (key === "it") ? LANGS["en"] : LANGS["it"];


    },
    fromCookie() {
        return cookies.getCookie(COOKIE_NAME);
    },
    savePreference(lang) {

        cookies.setCookie(COOKIE_NAME, lang);

        api.post(`accounting/student/set-lang/${lang}`, null);

    },
    translateFilter(value) {

        if (!value) return "";


        let langs = [LANGS_STATES.current.key, LANGS_STATES.alternate.key];

        for (let i = 0; i < langs.length; i++) {
            if (value[langs[i]] && value[langs[i]] !== "") {
                return value[langs[i]];
            }
        }

        return "";
    }
}