<template>
  <div id="app-inst-custom-scripts"></div>
</template>

<script>
import postscribe from "postscribe";

export default {

  mounted() {
    let advanced = this.APL.installation.data.info.entity.Theme_Advanced;
    if (advanced == null || advanced.custom_scripts == null || advanced.custom_scripts === "") return;

    postscribe("#app-inst-custom-scripts", advanced.custom_scripts);

  }
}
</script>