export default {
    debug: write
}

//region Members
var
    verbose_checked = false,
    verbose = false;
//endregion

//region Methods
function write(msg, obj, table) {

    if (!verbose_checked) {

        verbose = getUrlVars()["aplog"] === "1";
        verbose_checked = true;

        if (verbose) {
            console.log.apply(console, logWithColors(["####################"]));
            console.log.apply(console, logWithColors(["#### ApLearning ####"]));
            console.log.apply(console, logWithColors(["####################"]));
        }
    }

    if (verbose) {

        var args = [msg];
        if (obj) args.push(obj);

        console.log.apply(console, logWithColors(args));

        if (table) {
            console.table(table);
        }
    }
}

function logWithColors(e) {
    return e = [].slice.call(e), e.unshift("display: inline-block;font-weight:bold; color: #000; background: #ffdf00; padding: 1px 4px; border-radius: 3px;"), e.unshift("%capLearning"), e;
}


//endregion


//region Helpers
function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

//endregion