<template>
    <li class="list-inline-item" v-if="canChange">
        <a class="mhead-lang is-clickable"
           :title="i18n.alternate.menuTooltip"
           @click="changeLang">
            {{i18n.current.menuName}}
        </a>
    </li>
</template>

<script>
    export default {
        data() {
            return {
                i18n: this.APL.i18n.store
            }
        },
        computed: {
            canChange() {
                return this.APL.installation.data.info.entity.Theme_i18n.langs.length > 1;
            }
        },
        methods: {
            changeLang() {
                let new_lang = this.APL.i18n.store.alternate.key;

                this.APL.i18n.setCurrent(new_lang);
                this.APL.i18n.savePreference(new_lang);

                this.$root.$emit('langChanged');
            }
        }
    }
</script>

