import api from "../networking/apiclient";
import i18n from "../ui/i18n/i18n";

export default {

    data: null,

    init() {

        return new Promise(resolve => {

            if (this.data != null) {
                resolve(false);
                return;
            }

            api.post("installation/get", null).then(resp => {

                // normalize
                if (resp.info.entity.Theme_i18n == null) {
                    resp.info.entity.Theme_i18n = {"langs": ["it", "en"], "def_lang": "en"};
                }

                // set language
                let lang = resp.info.entity.Theme_i18n.def_lang;
                let preferred = i18n.fromCookie();
                if (preferred != null && resp.info.entity.Theme_i18n.langs.some(i => i === preferred)) {
                    lang = preferred;
                }

                i18n.setCurrent(lang);

                this.data = resp;
                resolve(true);
            });

        });
    }

}