<template>
  <footer class="mfoot">
    <div class="container">
      <ul class="list-inline list-inline--sep" v-if="inst.info.entity.Theme_Footer">

        <li class="list-inline-item"
            v-for="(i,index) in inst.info.entity.Theme_Footer.links" :key="index">

          <a class="mfoot-link" target="_blank"
             v-if="i.link_mode==='link' && $options.filters.trans(i.link)"
             :href="$options.filters.trans(i.link)">
            {{ i.text|trans }}
          </a>

          <router-link
              class="mfoot-link"
              v-else-if="i.link_mode==='page' && i.page"
              :to="'/app/out/pages/'+i.page">

            {{ i.text|trans }}

          </router-link>

          <span v-else class="mfoot-link">
              {{ i.text|trans }}
          </span>

        </li>

      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      inst: this.APL.installation.data
    }
  }
}
</script>

