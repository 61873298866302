import api from "../networking/apiclient";

export default {
    getOptions(courseId, webinarId) {
        return new Promise(resolve => {

            api.post(`courses/webinars/chat/get-options/${courseId}/${webinarId}`, null).then(resp => {
                resolve(resp);
            });

        });
    },
    getMsgOlder(courseId, webinarId, lastMsgId) {
        return new Promise(resolve => {

            if (!lastMsgId) {
                lastMsgId = 0;
            }

            api.post(`courses/webinars/chat/msg-older/${courseId}/${webinarId}/${lastMsgId}`, null).then(resp => {
                resolve(resp);
            });

        });
    },
    getNewData(courseId, webinarId, lastMsgId) {
        return new Promise(resolve => {

            if (!lastMsgId) {
                lastMsgId = 0;
            }

            api.post(`courses/webinars/chat/new-data/${courseId}/${webinarId}/${lastMsgId}`, null).then(resp => {
                resolve(resp);
            });

        });
    },
    postNewMessage(courseId, webinarId, dto) {
        return new Promise(resolve => {

            api.post(`courses/webinars/chat/postMessage/${courseId}/${webinarId}`, dto).then(resp => {
                resolve(resp);
            });

        });
    },
    deleteMessage(courseId, webinarId, messageId) {
        return new Promise(resolve => {

            api.post(`courses/webinars/chat/deleteMessage/${courseId}/${webinarId}/${messageId}`, null).then(resp => {
                resolve(resp);
            });

        });
    },
}