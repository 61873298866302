import api from "../networking/apiclient";
import log from "@/services/ui/log";

//region Api
export default {
    Create,
    StartPing,
    StopPing,
    SetCompleted,
    get SessionId() {
        return sessionId;
    }
}
//endregion

//region Members
let sessionId = null,
    pingEnabled = false;

//endregion

//region Methods
function Create(courseId, moduleId) {

    return new Promise(resolve => {

        log.debug("Creating new session...");

        api.post(`courses/sessions/start/${courseId}/${moduleId}`, null).then(resp => {

            sessionId = resp.sessionId;

            log.debug("Creating new session... done !", sessionId);

            resolve(resp);

        });

    });
}

function SetCompleted() {
    return new Promise(resolve => {

        log.debug("Setting session completed...");

        api.post(`courses/sessions/set-completed/${sessionId}`, null).then(() => {

            log.debug("Setting session completed... done !");

            resolve();

        });

    });
}

//region Ping
function StartPing() {
    log.debug("Ping enabled");
    pingEnabled = true;
}

function StopPing() {
    log.debug("Ping disabled");
    pingEnabled = false;
}

function Ping() {
    api.post(`courses/sessions/ping/${sessionId}`, null).then(resp => {
        log.debug("Ping done", resp);
    });

}

//endregion

//endregion

//region Main
setInterval(() => {

    if (pingEnabled && sessionId) {

        Ping();
    }

}, 5000);
//endregion


