<template>
  <div :class="customClasses">

    <fonts-cmp></fonts-cmp>

    <style-cmp></style-cmp>

    <header-cmp></header-cmp>

    <div class="main-content">

      <router-view/>

    </div>

    <footer-cmp></footer-cmp>

    <scripts-cmp></scripts-cmp>

  </div>
</template>

<script>
import styleCmp from "./partials/customization/styles"
import fontsCmp from "./partials/customization/fonts"
import scriptsCmp from "./partials/customization/scripts";
import headerCmp from "./partials/header/header"
import footerCmp from "./partials/footer/footer";

export default {
  components: {
    styleCmp,
    fontsCmp,
    headerCmp,
    footerCmp,
    scriptsCmp
  },
  computed: {
    customClasses() {
      let white = "#ffffff",
          colors = this.APL.colors,
          theme = this.APL.installation.data.info.entity.Theme_Colors,
          result = "";

      if (!theme) return;

      if (colors.suggestForeColor(colors.rgbColorToHex(theme.color_header_bg)) === white) {
        result = result + " darkhead";
      }

      if (colors.suggestForeColor(colors.rgbColorToHex(theme.color_footer_bg)) === white) {
        result = result + " darkfoot";
      }

      if (colors.suggestForeColor(colors.rgbColorToHex(theme.color_body_bg)) === white) {
        result = result + " darkmode";
      }

      if (this.$route.params.course == null) {
        result = result + " is-tools-out";
      }

      return result.trim();
    }
  },
  created() {

    // set document title
    document.title = this.APL.installation.data.info.entity.Name;
  }
}
</script>

